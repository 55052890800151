import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (window.ENV.SENTRY_DSN) {
  Sentry.init({
    dsn: window.ENV.SENTRY_DSN,
    environment: window.ENV.SENTRY_ENVIRONMENT,
    release: window.ENV.SENTRY_RELEASE,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        beforeNavigate: (context) => {
          return {
            ...context,
            trimEnd: true,
          }
        },
      }),
    ],
    tracesSampleRate: 0.1,
  })
  Sentry.setUser(window.ENV.SENTRY_USER)
}

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)
